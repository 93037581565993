<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">
            {{$t('dtouch.request.title', locale)}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.fullName', locale)}*`"
                  v-model="editedItem.FullName"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.email', locale)}*`"
                  v-model="editedItem.Email"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-text-field
                  :label="`${$t('dtouch.request.room', locale)}*`"
                  v-model="editedItem.Room"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
              >
                <v-textarea
                  :label="`${$t('dtouch.request.comment', locale)}`"
                  v-model="editedItem.Comment"
                  required
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onClose"
          >
            {{$t('common.close', locale)}}
          </v-btn>
          <v-btn
            :color="config.BGColor"
            @click="onSave(editedItem)"
            :style="{ color: config.Color }"
          >
            {{$t('common.save', locale)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    selectedID: {
      type: String,
      default: null,
    },
    workspaceID: {
      type: String,
      requried: true
    },
    locale: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      requried: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  watch: {
    selectedID (v) {
      if (v) {
        this.editedItem = {
          WorkspaceID: this.workspaceID,
          RequestsManagerID: v,
          FullName: null,
          Email: null,
          Room: null,
          Comment: null,
        }
        this.dialog = true
      } else {
        this.editedItem = null
        this.dialog = false
      }
    }
  }
}
</script>

